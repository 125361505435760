import { alertsService } from '../services';
import { genericConstants } from '../constants';

export const alertActions = {
  getParticipantAlerts
};

function getParticipantAlerts(id) {
  return dispatch => {
    dispatch(request());

    alertsService.getAlertsByParticipantId(id)
      .then(
        response => {
          response.sort((a, b) => {
            return new Date(b.alertDate) - new Date(a.alertDate);
          });

          dispatch(success(response));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() { return { type: genericConstants.PARTICIPANTS_ALERTS_REQUEST } }
  function success(items) { return { type: genericConstants.PARTICIPANTS_ALERTS_SUCCESS, items } }
  function failure(error) { return { type: genericConstants.PARTICIPANTS_ALERTS_FAILURE, error } }
}
