/*eslint-disable*/
import React, { Component } from "react";
import ReactTable from "react-table";
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Row, FormGroup, Form, Col, Button } from "reactstrap";
import Select from "react-select";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

import { isStudyStarted } from '../../helpers';
import { questionsListSelector } from '../../helpers';
import { questionsActions, formsActions } from '../../actions';
import { questionsService } from '../../services';

class Questions extends Component {
  constructor(props) {
    super(props);

    this.props.getAllQuestions();

    this.state = {
      form: {value: "",
        label: ""
      },
      data: []
    };

    if (props.lforms === undefined || props.lforms === null || props.lforms.length === 0) {
        props.getForms();
    }
  }

  handleFormChange(value) {
      //console.log("handleFormChange: ", value);
      this.setState({ form: value }, console.log("this.state.form: ", this.state.form));
      questionsListSelector(value);
      // this.props.activeForm = value;
      this.render();
      //console.log("this.props.activeForm: ", this.props.activeForm);
  }

  render() {
    var { questions } = this.props;
    //console.log(questions);

    if (questions && questions.length > 0) {

      var filterForm = questionsListSelector();//this.props.activeForm;
      this.state.form = filterForm;
      //console.log("filterForm: ", filterForm);
      //console.log("this.state.form: ", this.state.form);
      //console.log("this.props.activeForm: ", this.props.activeForm);

      /* CHECK IF ALL THESE VARIABLES ARE NECESSARY
      if(this.props.activeForm != null || this.props.activeForm.value != ""){
        if(this.state.form.value==""){
          console.log("this.props.activeForm: ", this.props.activeForm);
          this.state.form = this.props.activeForm;
          filterForm = this.props.activeForm;
        }
      }*/

      var dataItemsTemp = questions.map((el) => {
        var branchTemp = el.branchType;
        if(el.branchValue>0)
          branchTemp = branchTemp + " " + el.branchValue;

        if(el.numQuestionsToSkip>0)
          branchTemp = branchTemp + " SKIP= " + el.numQuestionsToSkip;

        var fType = this.state.form.label.substr(0, this.state.form.label.search("-")-1);
        var actions = <div className="actions-right">
              <Button
                onClick={() => {
                  askConfirmationDelete(el.uuid);
                }}
                className="btn-icon btn-round"
                color="danger"
                size="sm">
                <i className="fa fa-trash" />
              </Button>
              
              {"  "}
              <Button
                onClick={() => {
                  //console.log("id: ", el.id)
                  this.props.history.push({
                    pathname: `/admin/questions/${el.id}/edit/${fType}`,
                    state: { theQuestion: el }
                  });
                }}
                className="btn-icon btn-round"
                color="warning"
                size="sm">
                <i className="fa fa-edit" />
              </Button>
            </div>;
        
        if(isStudyStarted()){
          actions = <div className="actions-right">
              <Button
                onClick={() => {
                  this.props.history.push({
                    pathname: `/admin/questions/${el.id}/edit/${fType}`,
                    state: { theQuestion: el }
                  });
                }}
                className="btn-icon btn-round"
                color="warning"
                size="sm">
                <i className="fa fa-edit" />
              </Button>
            </div>;
        }

        return {
          id: el.id,
          uuid: el.uuid,
          text: el.text,
          hasanswers: el.answers.length,
          leftLabel: el.leftLabel,
          rightLabel: el.rightLabel,
          reverseResult: el.reverseResult,
          answerValues: el.answerValues,
          branchType: el.branchType,
          branchValue: el.branchValue,
          numQuestionsToSkip: el.numQuestionsToSkip,
          mobileGraphingCategory: el.mobileGraphingCategory,
          concept: el.concept,
          otherBranchIds: el.otherBranchIds,
          branchResume: branchTemp,
          form_id: el.form ? el.form.id : "",
          actions: actions
        };
      });

      var dataItems = dataItemsTemp.filter(function(elem){
        return elem.form_id == filterForm.value;
      });
    }

    const deleteQuestion =(uuid) => {
      questionsService.deleteQuestion(uuid).then(r => {
          if(r){
            this.setState({ alert: null })

            dataItems = dataItems.filter(function( obj ) {
              return obj.uuid !== uuid;
            });
  
            this.props.questions = this.props.questions.filter(function( obj ) {
                return obj.uuid !== uuid;
            });

            questions = questions.filter(function( obj ) {
              return obj.uuid !== uuid;
            });
  
            this.setState({data: this.state});
          } else {
            this.setState({
                alert: (
                    <SweetAlert
                        danger
                        style={{ display: "block", marginTop: "100px" }}
                        title="There was an error trying to delete the question"
                        onConfirm={() => this.setState({ alert: null })}
                        confirmBtnBsStyle="info"
                    >
                    </SweetAlert>
                )
            });
          }
      })
    }
  
    const askConfirmationDelete =(uuid) => {
        this.setState({ alert: null })
        this.setState({
            alert: (
              <SweetAlert
                danger
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                style={{ display: "block", marginTop: "100px" }}
                onConfirm={() => deleteQuestion(uuid)}
                onCancel={() => this.setState({ alert: null })}
                //focusCancelBtn
              >
                You will not be able to recover this Question!
              </SweetAlert>
            )
        });
    }

    var buttonAdd = <Button color="info" className="btn-round btn-icon pull-right"
                    onClick={() => {
                      var fType = this.state.form.label.substr(0, this.state.form.label.search("-")-1);
                      //console.log("form/form.label/fType/form.id: ", this.state.form, this.state.form.label, fType, this.state.form.value);
                      this.props.history.push(`/admin/questions/0/edit/${fType}/${this.state.form.value}`);
                    }}>
                    <i className="now-ui-icons ui-1_simple-add" />
                  </Button>;

    if(isStudyStarted()){
      buttonAdd = "";
    }

    return (
      <>
        {this.state.alert}
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  {buttonAdd}
                  <CardTitle tag="h4">Questionnaire: </CardTitle>
                  <Select
                      className="react-select"
                      classNamePrefix="react-select"
                      placeholder="Select a form"
                      name="form"
                      value={this.state.form}
                      options={this.props.lforms}
                      onChange={this.handleFormChange.bind(this)} />
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={dataItems}
                    filterable
                    columns={[
                      {
                        Header: "Order",
                        accessor: "id",
                        style: {"textAlign":"right"},
                        maxWidth: 50
                      },
                      {
                        Header: "Question",
                        accessor: "text",
                        minWidth: 400
                      },
                      {
                        Header: "Answer options",
                        accessor: "hasanswers",
                        style: {"textAlign":"right"},
                        maxWidth: 150 
                      },
                      {
                        Header: "Range Number",
                        accessor: "answerValues",
                        style: {"textAlign":"right"},
                        maxWidth: 150 
                      },
                      {
                        Header: "Branching",
                        accessor: "branchResume",
                        style: {"textAlign":"left"},
                        maxWidth: 300 
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        maxWidth: 150 
                      }
                    ]}
                    defaultPageSize={20}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { questions } = state.questions;
  const { forms } = state.forms;

  var lforms = [];
  
  var activeForm = questionsListSelector();
  //console.log("activeForm: ", activeForm);
  //var activeForm = {
  //  value: "",
  //  label: ""
  //}

  if (forms) {
      lforms = forms.map((f) => {
          //activeForm = {
          //    value: f.id,
          //    label: f.type + " - " + f.name
          //};
          return {
              value: f.id,
              label: f.type + " - " + f.name
          };
      });
  }

  return { questions, lforms, activeForm };
}

const actionCreators = {
  getAllQuestions: questionsActions.getAll,
  getForms: formsActions.getForms
};

const connectedQuestions = connect(mapState, actionCreators)(Questions);
export { connectedQuestions as Questions }; 