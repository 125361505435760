import React, { useEffect, useState } from 'react';
import { Button, Input, InputGroup, InputGroupText, NavLink } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import moment from 'moment';
import { PageWrapper } from 'components/PageWrapper/PageWrapper';

import { participantsActions } from '../../actions';
import { ServiceGraphsInsightRow } from './components/ServiceGraphsInsightRow';

const searchFilter = (participant, filterText) => {
  const { login, firstName, lastName } = participant.user;
  return (
    (login && login.toLowerCase().includes(filterText.toLowerCase())) ||
    (firstName && firstName.toLowerCase().includes(filterText.toLowerCase())) ||
    (lastName && lastName.toLowerCase().includes(filterText.toLowerCase()))
  );
};

const formatAlertStatus = (status) => {
  switch (status.toLowerCase()) {
    case 'ok':
      return 'OK';
    case 'alert':
      return 'EWS';
    default:
      return 'Unknown';
  }
};

const formatServiceUserName = (user) => {
  const name = [user.lastName, user.firstName];
  return name.map(part => part ?? '--').join(', ');
};

const ConditionalAlertColumnStyle = [
  {
    when: row => row.participant.status.toLowerCase() === 'alert',
    style: {
      backgroundColor: '#bd1000',
      fontWeight: 'bold',
      color: 'white',
    },
  }
]

const AdherenceIconRow = styled.div`
  display: flex;
  text-align: center;
  gap: 5px;
  
  i {
    &.fa-times-circle {
      color: #bd1000;
    }

    &.fa-check-circle {
      color: #00a11b;
    }

    &.fa-question-circle {
      color: #ff8c00;
    }
  }
`;

const TableHeaderTextStyle = styled.p`
  font-weight: normal;
  font-size: 1.2em;
`;

const FilterWrapper = styled.div`
  max-width: 300px;
  margin: -15px;

  input {
    height: 100%;
  }
`

const WeeklyAdherence = ({ epds, id }) => {
  const [adherence, setAherence] = useState([]);
  const today = new Date();
  const lastWeek = new Date(today);
  lastWeek.setDate(today.getDate() - 7);

  const dayLabels = Array.from({ length: 7 }, (_, i) => moment().subtract(i, 'days').format('dd'));

  useEffect(() => {
    if (epds) {
      const segments = epds.split('/');
      const booleanArray = segments.map(segment => segment !== '--');
      setAherence(booleanArray);
    }

  }, [epds]);

  return (
    <AdherenceIconRow>
      {adherence.map((a, i) => {
        if (i === 0 && !Boolean(a)) {
          return (
            <span className="flex flex-col" key={`${id}-${i}`}>
              {dayLabels[i]}
              <i key={i} className="fas fa-question-circle"></i>
            </span>
          );
        }

        return Boolean(a) ?
          <span className="flex flex-col" key={`${id}-${i}`}>
            {dayLabels[i]}
            <i key={i} className="fas fa-check-circle"></i>
          </span> :
          <span className="flex flex-col" key={`${id}-${i}`}>
            {dayLabels[i]}
            <i key={i} className="fas fa-times-circle"></i>
          </span>
      }
      )}
    </AdherenceIconRow>
  )
};

const epdsSortFunction = (rowA, rowB) => {
  const sumEPDS = (epds) => {
    const segments = epds.split('/');
    return segments.reduce((sum, segment) => {
      const num = parseFloat(segment);
      return sum + (isNaN(num) ? 0 : num);
    }, 0);
  };

  return sumEPDS(rowA.epds) - sumEPDS(rowB.epds);
};

const getTableColumns = (isAdmin) => {
  const columns = [
    {
      name: <TableHeaderTextStyle>Name</TableHeaderTextStyle>,
      selector: row => formatServiceUserName(row.participant.user),
      sortable: true,
    },
    {
      name: <TableHeaderTextStyle>Identifier</TableHeaderTextStyle>,
      selector: row => row.participant.user.login,
      sortable: true,
    },
    isAdmin && {
      name: <TableHeaderTextStyle>Provider</TableHeaderTextStyle>,
      selector: row => row.participant.provider.user.login,
      sortable: true,
    },
    {
      name: <TableHeaderTextStyle>Status</TableHeaderTextStyle>,
      selector: row => formatAlertStatus(row.participant.status),
      sortable: true,
      conditionalCellStyles: ConditionalAlertColumnStyle,
      center: true,
    },
    {
      name: <TableHeaderTextStyle>Last 7 Days</TableHeaderTextStyle>,
      cell: row => <WeeklyAdherence epds={row.epds} id={row.id} />,
      center: true,
      sortable: true,
      sortFunction: epdsSortFunction,
    },
    {
      name: <TableHeaderTextStyle>Actions</TableHeaderTextStyle>,
      cell: row => (
        <>
          <NavLink href={`/clintouch/admin/participants/${row.id}/summary`}>View</NavLink> |
          <NavLink href={`/clintouch/admin/participants/${row.id}/edit`}>Edit</NavLink>
        </>
      ),
      center: true,
    },
  ];

  // Filter out false (null) values from columns when isAdmin is false
  return columns.filter(Boolean);
};

export const ServiceUsersList = (props) => {

  const [filterText, setFilterText] = React.useState('');
  const columns = getTableColumns(window.localStorage.getItem('role') === 'ROLE_ADMIN');
  const {
    participants,
    participantsLoading: loading = true,
    //error,
  } = useSelector(state => state.participants);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.pathname.includes('/admin/participants')) {
      dispatch(participantsActions.getAll());
    } else {
      const id = window.localStorage.getItem('id');
      dispatch(participantsActions.getAllMy(id));
    }
  }, [dispatch]);

  const filteredItems = participants?.filter(item => searchFilter(item.participant, filterText));
  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <FilterWrapper>
        <InputGroup>
          <Input onChange={e => setFilterText(e.target.value)} className="h-full" placeholder="filter..." />
          <InputGroupText>
            Filter
          </InputGroupText>
        </InputGroup>
      </FilterWrapper>
    )
  }, []);

  const AddNewServiceUserButton = () => (
    <Button className="m-0" color="primary" href="/clintouch/admin/participants/0/edit">Add Service User</Button>
  );

  return (
    <PageWrapper title="Service Users" ActionButton={AddNewServiceUserButton}>
      <ServiceGraphsInsightRow />
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        progressPending={loading}
        subHeader subHeaderComponent={subHeaderComponentMemo} />
    </PageWrapper>
  );
};
