import {Providers} from "views/Providers/Providers.jsx"; 
import {LoginPage} from "views/Pages/LoginPage.jsx";
import PasswordRecovery from "views/Pages/PasswordRecovery.jsx";
import PasswordReset from "views/Pages/PasswordReset.jsx";
import {MFATokenLogin} from "views/Pages/MFATokenLogin.jsx";
import {ExportEncountersData} from 'views/Pages/ExportEncounterData';
import {ParticipantSummary} from 'views/Participants/ParticipantSummary';
import {ParticipantEncounters} from 'views/Participants/ParticipantEncounters';
import {ParticipantsEdit} from 'views/Participants/ParticipantsEdit';
import {ProvidersEdit} from 'views/Providers/ProvidersEdit';
import {Alarms} from 'views/Alarms/Alarms';
import {Questions} from 'views/Questions/Questions';
import {Answers} from 'views/Answers/Answers';
import {Screens} from 'views/Screens/Screens';
import {Settings} from 'views/Settings/Settings';
import {AlarmsEdit} from 'views/Alarms/AlarmsEdit';
import {QuestionsEdit} from 'views/Questions/QuestionsEdit';
import {AnswersEdit} from 'views/Answers/AnswersEdit';
import {ScreensEdit} from 'views/Screens/ScreensEdit';
import {SettingsEdit} from 'views/Settings/SettingsEdit';
import {OverviewStats} from 'views/Stats/OverviewStats';
import {TermsnConditions} from 'views/Legal/TermsnConditions';
import {PrivacyPolicy} from 'views/Legal/PrivacyPolicy';
import { ServiceUsersList } from 'views/ServiceUsers/ServiceUsersList';
import { AlertsEdit } from 'views/Alerts/AlertsEdit';
import { ServiceUserMobileSetup } from 'views/ServiceUsers/ServiceUserMobileSetup';

const HEADERS = Object.freeze({
  SETTINGS: 'Settings',
  MAINTENANCE: 'Maintenance',
  LEGAL: 'Legal'
});

let routes = [
  {
    role:"",
    visible:false,
    path: "/participants/:pid/alerts/:alertid/edit",
    component: AlertsEdit,
    layout: "/admin",
    name: "Alerts Editor",
  },
  {
    role:"",
    visible:false,
    path: "/participants/:pid/edit",
    component:ParticipantsEdit,
    layout: "/admin",
    name: "Service User Editor",
  },
  {
    role:"",
    visible:false,
    path: "/participants/:pid/summary",
    component:ParticipantSummary,
    layout: "/admin",
    name: "Service User Summary",
  },
  {
    role:"",
    visible:false,
    path: "/participants/:pid/setup",
    component:ServiceUserMobileSetup,
    layout: "/admin",
    name: "Service User Mobile Setup",
  },
  {
    role:"",
    visible:false,
    path: "/participants/:pid/encounters/:id",
    component:ParticipantEncounters,
    layout: "/admin",
    name: "Service User Encounters",
  },
  {
    role:"ROLE_ADMIN;ROLE_PROVIDER;ROLE_RESEARCHER",
    visible:true,
    path: "/participants",
    name: "Service Users",
    icon: "users",
    component: ServiceUsersList,
    layout: "/admin"
  },
  {
    role:"ROLE_PROVIDER;ROLE_RESEARCHER",
    visible:true,
    path: "/my-participants",
    name: "My Service Users",
    icon: "users",
    component: ServiceUsersList,
    layout: "/admin"
  },
  {
    role:"ROLE_ADMIN;ROLE_PROVIDER;ROLE_RESEARCHER",
    visible:true,
    path: "/dashboard",
    name: "Dashboard",
    icon: "chart-bar",
    component: OverviewStats,
    layout: "/admin"
  },
  {
    role:"",
    visible:false,
    path: "/providers/:pid/edit",
    component:ProvidersEdit,
    layout: "/admin",
    name: "Providers Editor",
  },
  {
    role:"ROLE_ADMIN",
    visible:true,
    path: "/providers",
    name: "Providers",
    icon: "user-circle",
    component: Providers,
    layout: "/admin"
  },
  {
    role:"",
    visible:false,
    path: "/alarms/:pid/edit",
    component:AlarmsEdit,
    layout: "/admin",
    name: "EWS Editor",
  },
  {
    role:"ROLE_ADMIN",
    visible:true,
    path: "/alarms",
    name: "EWS",
    icon: "bell",
    component: Alarms,
    sectionHeader: HEADERS.MAINTENANCE,
    layout: "/admin"
  },
  {
    role:"",
    visible:false,
    path: "/questions/:pid/edit/:ptype/:pform",
    component:QuestionsEdit,
    layout: "/admin",
    name: "Questions Add",
  },
  {
    role:"",
    visible:false,
    path: "/questions/:pid/edit/:ptype",
    component:QuestionsEdit,
    layout: "/admin",
    name: "Questions Editor",
  }, 
  {
    role:"",
    visible:false,
    path: "/answers/:pid/edit",
    component:AnswersEdit,
    layout: "/admin",
    name: "Answers Editor",
  },
  {
    role:"",
    visible:false,
    path: "/answers/:questionid",
    component:Answers,
    layout: "/admin",
    name: "Answers",
    sectionHeader: HEADERS.MAINTENANCE,
  },
  {
    role:"ROLE_ADMIN",
    visible:true,
    path: "/questions",
    name: "Questionnaire",
    icon: "poll-h",
    component: Questions,
    sectionHeader: HEADERS.MAINTENANCE,
    layout: "/admin"
  },
  {
    role:"",
    visible:false,
    path: "/screens/:pid/edit",
    component:ScreensEdit,
    layout: "/admin",
    name: "Screens Editor",
  },
  {
    role:"ROLE_ADMIN",
    visible:true,
    path: "/screens",
    name: "Screens",
    icon: "file",
    component: Screens,
    sectionHeader: HEADERS.MAINTENANCE,
    layout: "/admin"
  },
  {
    role:"",
    visible:false,
    path: "/settings/:pid/edit",
    component:SettingsEdit,
    layout: "/admin",
    name: "Settings Editor",
  },
  {
    role:"ROLE_ADMIN",
    visible:true,
    path: "/settings",
    name: "Settings",
    icon: "wrench",
    component: Settings,
    sectionHeader: HEADERS.MAINTENANCE,
    layout: "/admin"
  },
  {
    role:"ROLE_ADMIN;ROLE_RESEARCHER",
    visible:true,
    path: "/exports",
    name: "Export Study Data",
    icon: "file-export",
    component: ExportEncountersData,
    layout: "/admin"
  },
  {
    role:"ROLE_ADMIN;ROLE_PROVIDER;ROLE_RESEARCHER",
    visible:true,
    path: "/legal/TermsnConditions",
    name: "Terms & Conditions",
    icon: "file-contract",
    component: TermsnConditions,
    sectionHeader: HEADERS.LEGAL,
    layout: "/admin"
  },
  {
    role:"ROLE_ADMIN;ROLE_PROVIDER;ROLE_RESEARCHER",
    visible:true,
    path: "/legal/PrivacyPolicy",
    name: "Privacy Policy",
    icon: "info-circle",
    component: PrivacyPolicy,
    sectionHeader: HEADERS.LEGAL,
    layout: "/admin"
  },
  {
    role:"",
    visible:false,
    path: "/login-page",
    name: "Login Page",
    short: "Login",
    mini: "LP",
    component: LoginPage,
    layout: "/auth"
  }, 
  {
    role:"",
    visible:true,
    path: "/password-help",
    name: "Password Help",
    short: "Lock",
    mini: "PSH",
    component: PasswordRecovery,
    layout: "/auth"
  }, 
  {
    role:"",
    visible:true,
    path: "/password-reset/:token",
    name: "Password Reset",
    short: "Lock",
    mini: "PSH",
    component: PasswordReset,
    layout: "/auth"
  },
  {
    role:"",
    visible:true,
    path: "/mfa-token-login",
    name: "MFA Code",
    short: "Lock",
    mini: "MFA",
    component: MFATokenLogin,
    layout: "/auth"
  }
];

export default routes;
