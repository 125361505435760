export const alertConstants = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR'
};

export const alertResolutions = Object.freeze({
  NONE: '',
  REVIEWED_NO_ACTION: 'EWS reviewed – no further action taken',
  REVIEWED_WITH_PARTICIPANT_NO_ACTION: 'EWS reviewed with participant – no further action taken',
  REVIEWED_WITH_PARTICIPANT_ACTION_PLAN: 'EWS reviewed with participant – action as per individualised plan',
  REVIEWED_WITH_PARTICIPANT_INFO_SHARED_COORD: 'EWS reviewed with participant – information shared with care coordinator / CMHS',
  REVIEWED_WITH_PARTICIPANT_INFO_SHARED_DUTY: 'EWS reviewed with participant – information shared with CMHS Duty Worker / Crisis Intervention Service',
  REVIEWED_WITHOUT_PARTICIPANT_CARER_CONTACTED_NO_ACTION: 'EWS reviewed – participant unavailable – contact with nominated carer - no further action taken',
  REVIEWED_WITHOUT_PARTICIPANT_CARER_CONTACTED_INFO_SHARED_COORD: 'EWS reviewed – participant unavailable – contact with nominated carer - information shared with care coordinator / CMHS',
  REVIEWED_WITHOUT_PARTICIPANT_CARER_CONTACTED_INFO_SHARED_DUTY: 'EWS reviewed – participant unavailable – contact with nominated carer - information shared with CMHS Duty Worker / Crisis Intervention Service',
  REVIEWED_WITHOUT_PARTICIPANT_NO_CARER_NO_ACTION: 'EWS reviewed – participant unavailable – no nominated carer - no further action taken',
  REVIEWED_WITHOUT_PARTICIPANT_NO_CARER_INFO_SHARED_COORD: 'EWS reviewed – participant unavailable – no nominated carer - information shared with care coordinator / CMHS',
  REVIEWED_WITHOUT_PARTICIPANT_NO_CARER_INFO_SHARED_DUTY: 'EWS reviewed – participant unavailable – no nominated carer - information shared with CMHS Duty Worker / Crisis Intervention Service',
  REVIEWED_INFO_SHARED_DUTY: 'EWS reviewed - information shared with CMHS Duty Worker (Australia)'
});
